const FALLBACK_DDC_TIME = 10000; // 10 sec

(function(app, $){
	var config = app.preferences.googlePayPublicConfigurations;

	if (!config) {
		return;
	}

	var isProduction = app.debugMode === app.constants.PRODUCTION_SYSTEM;

	var $cache = {};
	var baseRequest = {
		apiVersion: 2,
		apiVersionMinor: 0,
		emailRequired: true
	};
	var allowedCardNetworks = config.allowedCardNetworks;
	var allowedCardAuthMethods = config.allowedCardAuthMethods;
	var baseCardPaymentMethod = {
		type: 'CARD',
		parameters: {
			allowedAuthMethods: allowedCardAuthMethods,
			allowedCardNetworks: allowedCardNetworks,
			billingAddressRequired: true,
				billingAddressParameters: {
					format: 'FULL',
					phoneNumberRequired: true
				}
		}
	};
	var tokenizationSpecification = {
		type: 'PAYMENT_GATEWAY',
		parameters: {
			'gateway': config.googlePayGateway,
			'gatewayMerchantId': config.googlePayGatewayMerchantId
		}
	};
	var cardPaymentMethod = Object.assign({},
		baseCardPaymentMethod, {
			tokenizationSpecification: tokenizationSpecification
		}
	);
	var paymentDataCallbacks = {
		onPaymentDataChanged: onPaymentDataChanged
	};
	var paymentsClient = null;


	function initializeCache(){
		$cache = {
			document: $(document),
			jsHideClassName: 'h-hidden',
			googlePayButton: $('.js-googlepay-btn'),
			submitOverlay: $('.js-submit-overlay')
		};
	}

	function initializeEvents() {
		$cache.document.on('minicart.load minicart.afterload minicart.product.removed cart.update.models cart.shippingCountryChange', function() {
			initializePageButtons();
		});

		if (app.device.isMobileView()) {
			$cache.document.on('fancy.mobile.added', function() {
				initializePageButtons();
			});
		}

		if ('popup' == app.preferences.cartAddProductAjaxTarget) {
			$cache.document.on('product.added', function() {
				initializePageButtons();
			});
		}
	}

	/*
	 * Make get request
	 * @input url:string
	 * @return Promise
	 */
	function getJson(url) {
		return fetch(url, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Accept': 'application/json'
			}
		}).then(responseHandler);
	}

	/*
	 * Make post request
	 * @input url : string
	 * @input data : object
	 * @return Promise
	 */
	function postJson(url, data) {
		var json = data;

		if(typeof data === 'object'){
			json = JSON.stringify(data);
		}
		else if(typeof data !== 'string'){
			throw new Error('Body data should be an object or a JSON string');
		}

		return fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: json
		}).then(responseHandler);
	}

	function responseHandler(response){
		return typeof response === 'object' ? response.json()
			.then(function(json){
				if(response.status >= 200 && response.status < 300){
					return json;
				}

				//Throw error in other case
				var err = new Error(json ? json.status : 'Request error');
				err.response = json;
				throw err;
			})
			: Promise.resolve(response);
	}

	function doRedirect(redirect) {
		if(redirect && window.location.href !== redirect){
			window.location = redirect;
		}
	}

	function log(message) {
		if (!isProduction) {
			console.log(message);
		}
	}

		/**
	 * Configure your site's support for payment methods supported by the Google Pay
	 * API.
	 *
	 * Each member of allowedPaymentMethods should contain only the required fields,
	 * allowing reuse of this base request when determining a viewer's ability
	 * to pay and later requesting a supported payment method
	 *
	 * @returns {object} Google Pay API version, payment methods supported by the site
	 */
	function getGoogleIsReadyToPayRequest() {
		const paymentDataRequest = Object.assign({}, baseRequest);

		paymentDataRequest.emailRequired = true;
		paymentDataRequest.shippingAddressRequired = true;
		paymentDataRequest.shippingAddressParameters = getShippingAddressParameters();
		paymentDataRequest.allowedPaymentMethods = [baseCardPaymentMethod];

		return paymentDataRequest;
	}

	/**
	 * Configure support for the Google Pay API
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
	 * @returns {object} PaymentDataRequest fields
	 */
	function getGooglePaymentDataRequest() {
		const paymentDataRequest = Object.assign({}, baseRequest);
		return getJson(app.urls.googlePayGetRequest).then((response) => {
			const result = response.result;
			config = result.publicConfig;
			cardPaymentMethod.tokenizationSpecification.parameters.gatewayMerchantId = result.publicConfig.googlePayGatewayMerchantId;
			paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
			paymentDataRequest.transactionInfo = getGoogleTransactionInfo(result);
			paymentDataRequest.merchantInfo = {
				// @todo a merchant ID is available for a production environment after approval by Google
				// See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
				merchantId: config.googlePayMerchantId,
				merchantName: config.googlePayMerchantName
			};

			paymentDataRequest.shippingAddressRequired = true;
			paymentDataRequest.shippingAddressParameters = getShippingAddressParameters(result);
			paymentDataRequest.shippingOptionRequired = true;
			paymentDataRequest.shippingOptionParameters = getShippingOptionParameters(result);
			paymentDataRequest.callbackIntents = ['SHIPPING_OPTION', 'SHIPPING_ADDRESS'];

			return Promise.resolve(paymentDataRequest);
		});
	}

	/**
	 * Provide Google Pay API with a payment amount, currency, and amount status
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
	 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
	 */
	function getShippingAddressParameters(result) {
		return {
			phoneNumberRequired: true,
			allowedCountryCodes: [result?.countryCode || app.user.country.value]
		}
	}

	/**
	 * Provide Google Pay API with a payment amount, currency, and amount status
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
	 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
	 */
	function getShippingOptionParameters(resultObject) {
		const shippingOptionParameters = {};

		if (resultObject?.success) {
			shippingOptionParameters.defaultSelectedOptionId = resultObject.shippingMethods[0].id;
			shippingOptionParameters.shippingOptions = resultObject.shippingMethods;
		}

		return shippingOptionParameters;
	}

	/**
	 * Provide Google Pay API with a payment amount, currency, and amount status
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
	 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
	 */
	function getGoogleTransactionInfo(response) {
		return {
			countryCode: response.contryCode || app.user.country.value,
			currencyCode: response.currencyCode || app.user.currencyCode,
			totalPriceStatus: 'FINAL',
			totalPriceLabel: 'Total',
			totalPrice: response?.totalPrice.toString()
		};
	}

	/**
	 * Return an active PaymentsClient or initialize
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
	 * @returns {google.payments.api.PaymentsClient} Google Pay API client
	 */
	function getGooglePaymentsClient() {
		if (paymentsClient === null) {
			paymentsClient = new google.payments.api.PaymentsClient({
				environment: isProduction ? 'PRODUCTION' : 'TEST',
				paymentDataCallbacks: paymentDataCallbacks
			});
		}
		return paymentsClient;
	}

	/**
	 * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
	 *
	 * Display a Google Pay payment button after confirmation of the viewer's
	 * ability to pay.
	 */
	function onGooglePayLoaded() {
		const paymentsClient = getGooglePaymentsClient();
		const request = getGoogleIsReadyToPayRequest();
		return paymentsClient.isReadyToPay(request)
			.then(function(response) {
				return Promise.resolve(response.result);
			})
			.catch(function(err) {
				// show error in developer console for debugging
				log(err);
				return Promise.reject(err);
			});
	}

	/**
	 * Add a Google Pay purchase button alongside an existing checkout button
	 * @param {Function} onClick - is debugging mode enabled
	 *
	 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
	 * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
	 */
	function getGooglePayButton(onClick) {
		const paymentsClient = getGooglePaymentsClient();
		const defaultButtonStyleConfig = {
			buttonColor: 'default',
  			buttonType: 'plain',
  			buttonRadius: 0,
		};
		const googlePayButtonStyleConfig = app.preferences?.googlePayExpressButtonConfig[app.page.type]?.buttonStyleConfig || {};
		const overwriteButtonStyleConfig = {
			...defaultButtonStyleConfig,
			...googlePayButtonStyleConfig
		};

		const button = paymentsClient.createButton({
			buttonSizeMode: 'fill',
			onClick: onClick,
			allowedPaymentMethods: [baseCardPaymentMethod],
			...overwriteButtonStyleConfig
		});

		return button;
	}

	/**
	 * Show Google Pay payment sheet when Google Pay payment button is clicked
	 */
	function onGooglePaymentButtonClicked() {
		getGooglePaymentDataRequest().then((paymentDataRequest) => {
			const paymentsClient = getGooglePaymentsClient();
			paymentsClient.loadPaymentData(paymentDataRequest)
				.then((paymentData) => {
					// handle the response
					processPayment(paymentData);
				})
				.catch((err) => {
					// show error in developer console for debugging
					log(err);
				});
		});
	}

	function onPaymentDataChanged(intermediatePaymentData) {
		return new Promise(function(resolve) {
			if (intermediatePaymentData.callbackTrigger === 'SHIPPING_OPTION') {
				postJson(app.urls.googlePayShippingMethodSelected, {
					shippingOptionData: intermediatePaymentData.shippingOptionData
				}).then((response) => resolve(resolvePaymentDataChanged(response)));
			} else if (intermediatePaymentData.callbackTrigger === 'SHIPPING_ADDRESS' || intermediatePaymentData.callbackTrigger === 'INITIALIZE') {
				postJson(app.urls.googlePayShippingContactSelected, {
						shippingOptionData: intermediatePaymentData.shippingOptionData,
						shippingAddress: {
							city: intermediatePaymentData.shippingAddress.locality,
							postalCode: intermediatePaymentData.shippingAddress.postalCode,
							stateCode: intermediatePaymentData.shippingAddress.administrativeArea,
							countryCode: intermediatePaymentData.shippingAddress.countryCode
						}
					}).then((response) => resolve(resolvePaymentDataChanged(response)));
			} else {
				resolve({});
			}
		});
	}

	function resolvePaymentDataChanged(response) {
		const resolveObject = {};
		const responseResult = response.result;

		if (responseResult?.success) {
			resolveObject.newTransactionInfo = getGoogleTransactionInfo(responseResult);
		}

		return resolveObject;
	}

	/**
	 * Process payment data returned by the Google Pay API
	 *
	 * @param {object} paymentData response from Google Pay API after user approves payment
	 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
	 */
	function processPayment(paymentData) {
		let tokenizationData = null;
		try {
			tokenizationData = JSON.parse(paymentData.paymentMethodData.tokenizationData.token);
		} catch (e) {
			log(e);
		}

		$cache.submitOverlay.removeClass($cache.jsHideClassName);

		const parsedShippingName = parseGoogleName(paymentData.shippingAddress.name);
		const parsedBillingName = parseGoogleName(paymentData.paymentMethodData.info.billingAddress.name);

		getThreeDSDDC().then((dfReferenceId) => {
			postJson(app.urls.googlePayPaymentAuthorized, {
				email: paymentData.email,
				shippingAddress: {
					firstName: parsedShippingName.firstName,
					lastName: parsedShippingName.lastName,
					address1: paymentData.shippingAddress.address1,
					address2: paymentData.shippingAddress.address2,
					city: paymentData.shippingAddress.locality,
					postalCode: paymentData.shippingAddress.postalCode,
					stateCode: paymentData.shippingAddress.administrativeArea,
					countryCode: paymentData.shippingAddress.countryCode,
					phoneNumber: paymentData.shippingAddress.phoneNumber
				},
				billingAddress: {
					firstName: parsedBillingName.firstName,
					lastName: parsedBillingName.lastName,
					address1: paymentData.paymentMethodData.info.billingAddress.address1,
					address2: paymentData.paymentMethodData.info.billingAddress.address2,
					city: paymentData.paymentMethodData.info.billingAddress.locality,
					postalCode: paymentData.paymentMethodData.info.billingAddress.postalCode,
					stateCode: paymentData.paymentMethodData.info.billingAddress.administrativeArea,
					countryCode: paymentData.paymentMethodData.info.billingAddress.countryCode,
					phoneNumber: paymentData.paymentMethodData.info.billingAddress.phoneNumber
				},
				shippingOptionData: paymentData.shippingOptionData,
				tokenizationData: tokenizationData,
				dfReferenceId: dfReferenceId
			}).then((data) => {
				if (data.success && data.challengeDetails && data.challengeDetails.threeDSJWT) {
					$cache.submitOverlay.addClass($cache.jsHideClassName);
					app.popupsMgr.open('WorldpayThreeDS', data.challengeDetails.threeDSJWT);
					window.addEventListener('message', on3DSResponse, false);

					function on3DSResponse(event) {
						if (event.origin === window.location.origin) {
							const data = event.data;

							if (data && data.queryParams && data.queryParams.redirectURL) {
								$cache.submitOverlay.removeClass($cache.jsHideClassName);
								app.fancybox.close();

								window.removeEventListener('message', on3DSResponse, false);
								window.location.href = data.queryParams.redirectURL;
							}
						}
					}
				} else if (data.success && data.continueURL) {
					doRedirect(data.continueURL);
				} else {
					clearBasketAddresses();
					$cache.submitOverlay.addClass($cache.jsHideClassName);
				}
			}).catch((e) => {
				log(e);
				clearBasketAddresses();
				$cache.submitOverlay.addClass($cache.jsHideClassName);
			});
		});
	}

	function clearBasketAddresses() {
		postJson(app.urls.googlePayCancel);
	}

	/**
	 * Parse google name field to first name and last name
	 * @param {string} fullName - full name provided by google
	 */
	function parseGoogleName(fullName) {
		const parsedName = fullName.split(' ');
		const nameLength = parsedName.length;
		let firstName = '';
		let lastName = '';

		if (nameLength === 1) {
			firstName = parsedName[0];
			lastName = parsedName[0];
		}

		if (nameLength === 2) {
			firstName = parsedName[0];
			lastName = parsedName[1];
		}

		if (nameLength > 2) {
			firstName = parsedName.slice(0, 2).join(' ');
			lastName = parsedName.slice(2).join(' ');
		}

		return {
			firstName: firstName,
			lastName: lastName
		};
	}

	function createIframe({ src = 'about:blank', body, iframeConfig = {} }) {
		const iframe = document.createElement('iframe');

		iframe.setAttribute('src', src);
		iframe.style.width = (iframeConfig.width || '0') + 'px';
		iframe.style.height = (iframeConfig.height || '0') + 'px';
		iframe.style.display = 'none';

		document.body.appendChild(iframe);

		if (body) {
			iframe.contentWindow.document.body.innerHTML = body;
		}

		return iframe;
	}

	function getIframeDDCLayout(ddcJWT) {
		return `
			<form name="devicedata" method="POST" action="${app.preferences.worldpayCardinalCommerceURL}/V1/Cruise/Collect" onload="this.submit();">
				<input type="hidden" name="Bin" value="" />
				<input type="hidden" name="JWT" value="${ddcJWT}" />
			</form>
		`;
	}

	function getDDCJWT() {
		return fetch(app.urls.worldpayDDC).then(response => response.json());
	}

	function getThreeDSDDC() {
		return getDDCJWT().then(function (response) {
			const iframe = createIframe({ body: getIframeDDCLayout(response.JWT) });

			const resultPromise = new Promise((resolve) => {
				window.addEventListener('message', onDDCResponse, false);

				const fallbackTimeout = setTimeout(() => {
					iframe.remove();
					window.removeEventListener('message', onDDCResponse, false);

					resolve('');
				}, FALLBACK_DDC_TIME);

				function onDDCResponse(event) {
					if (event.origin === app.preferences.worldpayCardinalCommerceURL) {
						const data = JSON.parse(event.data);

						if (data && data.Status && data.SessionId) {
							iframe.remove();
							window.removeEventListener('message', onDDCResponse, false);

							$('#worldpay-dfReferenceId').val(data.SessionId);

							resolve(data.SessionId);
							clearTimeout(fallbackTimeout);
						}
					}
				}
			});

			iframe.contentWindow.document.forms.devicedata.submit();

			return resultPromise;
		}).catch(err => Promise.resolve(err));
	}

	function initializePageButtons() {
		onGooglePayLoaded().then(() => {
			$('.js-googlepay-btn').each((index, el) => {
				if(el && !el.firstChild) {
					const button = getGooglePayButton(onGooglePaymentButtonClicked);
					el.append(button);
				}
			});
		});
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.googlepay = {
		init: function() {
			if('https:' === document.location.protocol && window.google && window.google.payments){
				initializeCache();
				initializeEvents();
				onGooglePayLoaded()
					.then(() => {
						$cache.googlePayButton.each((index, el) => {
							const button = getGooglePayButton(onGooglePaymentButtonClicked)
							el.append(button);
						});
					});
			}
		},
		initCheckout: function() {
			onGooglePaymentButtonClicked();

			return {
				stopPropagation: true
			};
		}
	};

})(window.app = window.app || {}, jQuery, window.dw = window.dw || {});