const FALLBACK_DDC_TIME = 10000; // 10 sec
const CARDINAL_COMMERCE_URL = app.debugMode === app.constants.PRODUCTION_SYSTEM // production have separate url
	? 'https://centinelapi.cardinalcommerce.com'
	: 'https://centinelapistag.cardinalcommerce.com';

class WorldpayCreditCard {
	init() {
		collectBrowserData();

		return $('#worldpay-dfReferenceId[data-enabled3ds="true"]').length ? this.initDDC() : Promise.resolve();
	}

	initDDC() {
		const cardBin = getWorldpayCardNumber().slice(0, 6);

		if (cardBin) {
			return getDDCJWT().then(function (response) {
				const iframe = createIframe({ body: getIframeDDCLayout(cardBin, response.JWT) });

				const resultPromise = new Promise((resolve) => {
					window.addEventListener('message', onDDCResponse, false);

					const fallbackTimeout = setTimeout(() => {
						iframe.remove();
						window.removeEventListener('message', onDDCResponse, false);

						resolve('');
					}, FALLBACK_DDC_TIME);

					function onDDCResponse(event) {
						if (event.origin === CARDINAL_COMMERCE_URL) {
							const data = JSON.parse(event.data);

							if (data && data.Status && data.SessionId) {
								iframe.remove();
								window.removeEventListener('message', onDDCResponse, false);

								$('#worldpay-dfReferenceId').val(data.SessionId);

								resolve(data.SessionId);
								clearTimeout(fallbackTimeout);
							}
						}
					}
				});

				iframe.contentWindow.document.forms.devicedata.submit();

				return resultPromise;
			}).catch(err => Promise.resolve());
		}

		return Promise.resolve();
	}

	process3DSModal(threeDSJWT) {
		app.popupsMgr.open('WorldpayThreeDS', threeDSJWT);

		return new Promise((resolve) => {
			window.addEventListener('message', on3DSResponse, false);

			function on3DSResponse(event) {
				if (event.origin === window.location.origin) {
					const data = event.data;

					if (data && data.queryParams && data.queryParams.redirectURL) {
						app.fancybox.close();
						window.removeEventListener('message', on3DSResponse, false);
						resolve(data.queryParams.redirectURL);
					}
				}
			}
		});
	}
}


function collectBrowserData() {
	$('#worldpay-browser-data').val(JSON.stringify({
		browserScreenHeight: screen.height,
		browserScreenWidth: screen.width
	}));
}

function getWorldpayCardNumber() {
	const worldpayCardNumber = document.getElementById('worldpay-card-number');
	const inputCardNumber = document.getElementById('dwfrm_billing_paymentMethods_creditCard_number');

	return (worldpayCardNumber?.value || inputCardNumber?.value || '').replaceAll(' ', '');
}

function getDDCJWT() {
	const url = new URL(app.urls.worldpayDDC)

	url.searchParams.set('cardNumber', getWorldpayCardNumber());

	return fetch(url)
		.then(response => response.json());
}

function createIframe({ src = 'about:blank', body, iframeConfig = {} }) {
	const iframe = document.createElement('iframe');

	iframe.setAttribute('src', src);
	iframe.style.width = (iframeConfig.width || '0') + 'px';
	iframe.style.height = (iframeConfig.height || '0') + 'px';
	iframe.style.display = 'none';

	document.body.appendChild(iframe);

	if (body) {
		iframe.contentWindow.document.body.innerHTML = body;
	}

	return iframe;
}

function getIframeDDCLayout(cardBin, ddcJWT) {
	return `
		<form name="devicedata" method="POST" action="${CARDINAL_COMMERCE_URL}/V1/Cruise/Collect" onload="this.submit();">
			<input type="hidden" name="Bin" value="${cardBin}" />
			<input type="hidden" name="JWT" value="${ddcJWT}" />
		</form>
	`;
}

export default new WorldpayCreditCard();
